
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Container } from 'react-bootstrap'

import StepsButtons from './StepsButtons'
import { HeaderTitle, HeaderSubTitle } from './Text'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  width:90vw;
  align-self:center;
`

function StepsHeader(props) {

  return (
    <Container fluid className="header">
      <div className={`header-div`} >
        <Col >
          <HeaderTitle>Faites votre devis épilation laser en ligne !</HeaderTitle>
          <HeaderSubTitle>Suivez chacune des étapes puis recevez votre devis par mail.</HeaderSubTitle>
        </Col>

        <Col >
          <StepsButtons
            selected={props.selected}
            className="card-margin-top"
            firstButtonClick={props.firstButtonClick}
            secondButtonClick={props.secondButtonClick}
            thirdButtonClick={props.thirdButtonClick}
          />
        </Col>
      </div>
    </Container>
  );
}

export default StepsHeader;
