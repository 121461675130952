
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'




export const AreaButton = styled.button`
        width: 100%; 
        box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .05); 
        background-color: #fff; 
        color: #102935; 
        font-family: CenturyGothicRegular; 
        border-width: 3px; 
        border-botom-width:0px;
        border-color: #fff; 
        font-size: 15px; 
        margin-left: 0; 
        min-height: 45px;
        outline: none;
        border-style: solid;
        margin-bottom:10px;
        transition: 0.3s;

        &:-moz-focus-inner {
            padding: 0;
            border: 0
        }

        &:hover, &.selected {
            color: #F0B594;
            border-color: #F0B594; 
        }
        &.big {
            width: 354px;
            height: 62px;
        }
        &.small {
            width: 108px;
            height: 49px;
            :nth-child(odd) {
                margin-right:10px
              } 
              
        }
        &.margin-left {
           margin-left: 10px
        }

        &.disabled {
            color: grey;
            box-shadow: none;
            cursor: not-allowed;
        }

        &.disabled:hover {
            color: grey;
            border-color: #fff; 
        }
        
        

    `
//&.city-button {
            
//     @media screen and (max-width:1204px) {
            
//         width:30vw;
//         :nth-child(2) {
//           margin-left:20px
//         }
//     }
//   }

export default AreaButton;
