
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'

import { Image } from 'react-bootstrap'


function StepsButtons(props) {

    const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:295px;
  height:34px;
  border-radius:17px;
  background-color:rgba(255,255,255,.2);
`
    const Step = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent; 
    border: 0 ;
    width: 74px ;
    font-family: CenturyGothicBold ;
    font-size: 15px ;
    height:34px;
    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0px; 
    border-top-left-radius: 0px; 
    border-bottom-left-radius: 0px !important; 
    color: #fff; 
    border:none !important;
    &.selected {
        background-color:#fff;
        color: #F0B594;
    }
    &.first {
        border-top-left-radius: 17px !important;
        border-bottom-left-radius: 17px !important;
    }
    &.last {
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
    }

`


    return (
        <StepsContainer className={props.className}>
            <Step
                className={`${props.selected ? "selected" : ""} first ${props.selected.length === 1 ? "last" : ""}`}
                onClick={props.firstButtonClick}
            >
                {props.selected.length > 1 ?
                    <Image src="/tick.svg" />
                    :
                    1
                }
            </Step>
            <Step
                className={`${props.selected.length >= 2 ? "selected" : ""}  ${props.selected.length === 2 ? "last" : ""}`}
                onClick={props.secondButtonClick}
            >
                {props.selected.length > 2 ?
                    <Image src="/tick.svg" />
                    :
                    2
                }
            </Step>
            <Step
                className={`${props.selected.length >= 3 ? "selected" : ""}  ${props.selected.length === 3 ? "last" : ""}`}
                onClick={props.thirdButtonClick}
            >
                {props.selected.length > 3 ?
                    <Image src="/tick.svg" />
                    :
                    3
                }
            </Step>
            <Step
                className={`${props.selected.length === 4 ? "selected" : ""}  last `}
            >
                4
            </Step>
        </StepsContainer>
    );
}

export default StepsButtons;
