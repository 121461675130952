import styled from 'styled-components'

let primaryColor = "#102935"
let secondaryColor = "#F0B594"
let labelColor = "#707070"

export const Title = styled.text`
    font-family: CenturyGothicBold ;
    font-size: 18px;
    color: ${primaryColor}
`

export const SubTitle = styled.text`
font-family: CenturyGothicRegular ;
font-size: 15px ;
color: ${primaryColor};

`

export const HeaderTitle = styled.text`
color: #fff;
font-family: CenturyGothicBold;
font-size:20px;
text-align:center
`
export const HeaderSubTitle = styled.text`
color: ${secondaryColor};
font-family: CenturyGothicRegular;
font-size:15px;
text-align:center
`

export const CardTitle = styled.text`
font-family: CenturyGothicBold ;
font-size: 13px ;
color: ${primaryColor}
`
export const CardDeleteText = styled.text`
font-family: CenturyGothicBold ;
font-size: 13px ;
color: #D02B42
`

export const Label = styled.text`
font-family: CenturyGothicBold ;
font-size: 15px ;
color: ${labelColor};
margin:0px;

margin-bottom:5px
`
export const SecondaryTitle = styled.text`
font-family: CenturyGothicBold ;
font-size: 20px ;
color: ${secondaryColor};
margin:0px;
`