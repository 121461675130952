
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'



export const Button = styled.button`
    background-color: #F0B594 ;
      border: 0 ;
      box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .05) ;
      width: 100% ;
      font-family: CenturyGothicBold ;
      font-size: 15px ;
      height:42px;
      color:#fff;
      align-self:center;
      &.disabled {
        background-color: #D1D1D1 ;
        cursor: not-allowed;
    }
    `


export default Button;
