
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'



export const OptionButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; 
    box-shadow:1px 1px 5px 5px rgba(0, 0, 0, .05); 
    background-color: #fff; 
    color: #102935; 
    font-family: CenturyGothicBold; 
    border-width: 3px; 
    border-color: #fff; 
    font-size: 20px; 
    margin-left: 0; 
    height: 175px;
    outline: none;
    border-radius:3px;
    transition: 0.3s;
    border-style: solid;
    
    &:-moz-focus-inner {
        padding: 0;
        border: 0
    }
    &:hover, &.selected {
        color: #F0B594;
        border-color: #F0B594; 
    }

`



export default OptionButton;
