
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'


function CircleC(props) {

    const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #B4CFD7;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-family: CenturyGothicBold ;
    font-size: 21px ;
    color:#fff;
  `

    return (

        <Circle>
            {props.text}
        </Circle>
    );
}

export default CircleC;
