
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Label } from './Text'

// function InputC(props) {
//     const Input = styled.input`
//     background-color: #FEF8F8 ;
//       border: 1px ;
//       border-color:rgba(68, 89, 128, .1);
//       width: 100% ;
//       font-family: CenturyGothicRegular ;
//       font-size: 15px ;
//       height:49px;
//       color:#6B7389;
//       border-style: solid;
//       border-radius:2px;
//       padding-left:10px
//     `

//     return (
//         <Col className="col">
//             <Label>{props.label}</Label>
//             <Input
//                 className={props.className}
//                 name={props.name}
//                // ref={props.ref}
//                 ref={props.ref}
//             />
//         </Col>



//     );
// }

export default styled.input`
    background-color: rgba(253, 244, 239, .7) ;
      border: 1px ;
      border-color:rgba(68, 89, 128, .1);
      width: 100% ;
      font-family: CenturyGothicRegular ;
      font-size: 15px ;
      height:49px;
      color:#6B7389;
      border-style: solid;
      border-radius:2px;
      padding-left:10px;
      &.form-button {
        background-color: #F0B594 ;
        color:#fff;
        border: 0 ;
        box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .05) ;
      
    }
    &.disabled {
      background-color: #D1D1D1 ;
      cursor: not-allowed;
  }
    `;
