import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment';
import fr from 'moment/locale/fr';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import QuotationPersonalInformations from './screens/QuotationPersonalInformations'
import QuotationGender from './screens/QuotationGender'
import QuotationAreas from './screens/QuotationAreas'
import QuotationReady from './screens/QuotationReady'

import store from './redux/Store'

function App() {

  useEffect(() => {

    moment.updateLocale('fr', fr);

  }, [])


  return (
    <Provider store={store}>
      <Router>

        <ToastContainer
          newestOnTop={true}
        />


        <Switch>
          <Route exact path="/gender" render={() => {
            return <QuotationGender />
          }}>
          </Route>
          <Route exact path="/areas" render={() => {
            return <QuotationAreas />
          }}>
          </Route>
          <Route exact path="/informations" render={() => {
            return <QuotationPersonalInformations />
          }}>
          </Route>
          <Route exact path="/ready" render={() => {
            return <QuotationReady />
          }}>
          </Route>
          <Route render={() => {
            return <Redirect to="/gender" />
          }}>
          </Route>
        </Switch>
      </Router>
    </Provider>

  );
}

export default App;
