
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import '../App.css';
import { Container, Row, Col, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import { useSelector } from 'react-redux'

//cmponents
import AreaButton from '../components/AreaButton'
import Button from '../components/Button'
import Circle from '../components/Circle'
import { Title, SubTitle, SecondaryTitle } from '../components/Text'
import StepsHeader from '../components/StepsHeader'

import MyLaserApi from '../api/MyLaserApi'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Line = styled.div`
   width:100%;
   height:2px;
   background-color:#102935;
   align-self:center;
   &.small {
    width: 300px;
    align-self: flex-end;
    @media screen and (max-width:1204px) {
        width: 20vw;
    }
}
`


function QuotationReady() {

    let history = useHistory();

    const client = useSelector(state => state.client)
    const packageType = useSelector(state => state.packageType)
    const pricingR = useSelector(state => state.pricing)
    const [ref, setRef] = useState(null);
    const [pricing, setPricing] = useState(null);
    const city = useSelector(state => state.city)

    const light = useSelector(state => state.light)


    if (client === null) {
        history.goBack()
    }

    useEffect(() => {

        if (pricing) {
            setRef(pricing.quotation_reference)
        }

        setPricing(pricingR)

        console.log('pricingR', pricingR)
        console.log('pricing', pricing)

        

    }, [])

    return (
        <Container fluid style={{ padding: 0 }}  >
            <StepsHeader
                selected={[1, 2, 3, 4]}
                firstButtonClick={() => history.push('/gender')}
                secondButtonClick={() => history.push('/areas')}
                thirdButtonClick={() => history.goBack()}
            />
            <Container style={{ maxWidth: "1400px" }}>
                <Col >
                    <Row className={"mt-4"}>
                        <Circle
                            text="4"
                        />

                        <Col  >
                            <Title>Votre devis est prêt ! </Title>
                            <SubTitle>Téléchargez-le afin d’obtenir les détails. Une copie vous a également été envoyée par mail..</SubTitle>

                        </Col>
                    </Row>
                    <Col md={12}>
                        <Row className={"mt-4"} style={{ alignSelf: 'center' }} >
                            <Col md={6} >
                                {client && <SubTitle>{client.firstname}  {client.lastname}<br />
                                    {client.address} <br />
                                    {client.city}
                                </SubTitle>
                                }
                            </Col>
                            <Col md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <SecondaryTitle>DEVIS MY LASER<br /></SecondaryTitle>
                                <SubTitle style={{ textAlign: 'right' }}>Le {moment(new Date()).format('DD MMMM YYYY')} à {moment(new Date()).format('HH:mm').split(':')[0] + 'h' + moment(new Date()).format('HH:mm').split(':')[1]}<br />

                                </SubTitle>

                            </Col>
                        </Row>
                        <Line className={"mt-4"} />
                        <Row className={"mt-4"} style={{ alignSelf: 'center' }} >
                            <Col md={5} sm={12} xs={12} className="col">
                                <SecondaryTitle>Zones de traitement SELECTIONNÉES</SecondaryTitle>
                                {pricing && pricing.treatement_areas.map((el) => {

                                    if (el.price == el.price_before) {
                                        return (<SubTitle>
                                            {el.name} - {el.price.toFixed ? el.price.toFixed(2) : el.price}{window.currencySymbol}
                                        </SubTitle>)
                                    } else if (el.price_before_voucher) {
                                        return (<SubTitle>
                                            {el.name} - {el.price.toFixed ? el.price.toFixed(2) : el.price}{window.currencySymbol} 
                                            <i style={{color: 'red'}}>(Reduction {el.price_before_voucher.toFixed ? el.price_before_voucher.toFixed(2) : el.price_before_voucher}{window.currencySymbol} -20%)</i> 
                                            <i style={{textDecoration: 'line-through'}}>{el.price_before.toFixed ? el.price_before.toFixed(2) : el.price_before}{window.currencySymbol}</i>
                                        </SubTitle>)

                                    } else {

                                        return (<SubTitle>
                                            {el.name} - {el.price.toFixed ? el.price.toFixed(2) : el.price}{window.currencySymbol} <i style={{textDecoration: 'line-through'}}>{el.price_before.toFixed ? el.price_before.toFixed(2) : el.price_before}{window.currencySymbol}</i>
                                        </SubTitle>)
                                        
                                    }

                                    
                                })}
                            </Col>
                            <Col md={3} sm={12} xs={12} className="col" >
                                <SecondaryTitle>Type de séance</SecondaryTitle>
                                <SubTitle>
                                    {packageType === 'x1' ? "Forfait 1 Séance" : "Forfait 5 Séances Multi-Zones"}
                                </SubTitle>
                            </Col>
                            <Col md={4} sm={12} xs={12} className="col" style={{ alignItems: 'flex-end' }}>
                                <Title>TOTAL ({packageType === "x1" ? "Forfait 1 Séance" : "Forfait 5 Séances Multi-Zones"})</Title>
                                <Line className={"mt-4"} />
                                {pricing && <Title style={{ fontSize: 35 }}>{pricing.amount.toFixed ? pricing.amount.toFixed(2) : pricing.amount}{window.currencySymbol}</Title>}
                                {pricing && pricing.old_amount != pricing.amount && <SubTitle>
                                    au lieu de {pricing.old_amount.toFixed ? pricing.old_amount.toFixed(2) : pricing.old_amount} {window.currencySymbol}
                            </SubTitle>
                            
                                }

                                <SubTitle style={{color: 'black', fontSize: 12}}> <br/> *Prix par séance</SubTitle>
                                <br/>
                                {/* {light && 

                                    <Button
                                        onClick={() => {


                                            console.log(pricing)

                                            setPricing({...pricing, treatement_areas: pricing.treatement_areas.map((el) => { 

                                                if (el.id != [999] && el.id != [108]) {
                                                    el.old_amount = el.amount
                                                    el.amount = el.amount * 0.8
                                                }

                                                return el

                                            }) })
                                            
                                        }}

                                    >
                                        Activer la reduction influenceurs
                                    </Button> 
                                } */}

                            </Col> 
                        </Row>
                    </Col>

                    {!client.id && <Col md={12} >
                        {!light &&  <Row className={"mt-4 justify-content-end"} >
                            {window.country === "fr" && <Col md={4}>

                                <AreaButton
                                    style={{ height: 42 }}
                                    className={"selected"}
                                    onClick={() => {
                                        if (city === 1) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/saint-denis/my-laser-center-plaine-saint-denis", "_blank")
                                        } else if (city === 2) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/lyon/my-laser-lyon-2", "_blank")
                                        } else if (city === 3) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/marseille/my-laser-marseille", "_blank")
                                        } else if (city === 4) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/toulouse/my-laser-toulouse", "_blank")
                                        } else if (city === 5) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/nantes/my-laser-nantes", "_blank")
                                        } else if (city === 6) {
                                            window.open("https://www.doctolib.fr/centre-de-sante/bois-colombes/sante-partner-centre-d-ophtalmologie", "_blank")
                                        } else if (city === 7) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/paris/my-laser-rivoli", "_blank")
                                        } else if (city === 8) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/nice/my-laser-nice", "_blank")
                                        } else if (city === 9) {
                                            window.open("https://www.doctolib.fr/centre-laser-et-esthetique/bordeaux/my-laser-bordeaux", "_blank")
                                        }
                                    }}
                                //window.open("http://mylaser.etherial.fr/api/quotations/" + ref + '.pdf', "_blank")}
                                >
                                    Prendre un RDV de consultation GRATUIT
                               </AreaButton>

                            </Col>}
                            <Col md={3}>

                                <Button
                                    onClick={() => window.open("http://mylaser.etherial.fr/api/quotations/" + pricingR.quotation_reference + '.pdf', "_blank")}
                                >
                                    Télécharger le devis PDF
                                    </Button> 

                            </Col>
                        </Row> }

                        

                        {light &&  <Row className={"mt-4 justify-content-end"} >
                            <Col md={12}>

                                <Button
                                    onClick={() => history.push('/areas')}
                                >
                                    Retourner aux zones
                                </Button> 

                            </Col>
                        </Row> }

                    </Col> }

                    {client.id && <Col md={12} >

                        <Row className={"mt-4 justify-content-end"}>

                            <Col md={12}>

                                <Button
                                    onClick={() => {

                                        toast.info("En cours de traitement...")

                                        // MyLaserApi.upgradeQuotation(pricingR.quotation_reference).then((res) => {
                                        window.parent.postMessage({name: "end-quotation-with-id-accepted", id: pricingR.quotation_id},  '*')
                                        // })
                                    }} 
                                >
                                    Je confirme ce devis
                                </Button> 

                            </Col>

                            <Col md={12}>

                                <br />

                                <Button
                                    style={{backgroundColor: "#dc3545"}}
                                    onClick={() => {
                                        // window.parent.postMessage("end-quotation",  '*')
                                        window.parent.postMessage({name: "end-quotation-with-id-refused", id: pricingR.quotation_id},  '*')
                                    }} 
                                >
                                    Je ne confirme pas ce devis
                                </Button> 

                            </Col>

                        </Row> 

                    </Col>}
                    
                </Col>

            </Container>

        </Container >
    );
}

export default QuotationReady;
