
import store from '../redux/Store';

export default class MyLaserApi {

    // static baseUrl = 'https://mylaser.etherial.fr/api'
    
    // static baseUrl = 'http://localhost:3031/api'

    static getQuotationItems(gender, type) {

        return new Promise((resolve, reject) => {
            fetch(`${window.baseUrl}/api/quotations/items?gender=${gender}&type=${type}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status === 200) {
                        if (gender === 'female' && type === "face") {
                            store.dispatch({ type: 'SET_FEMALE_FACE_AREAS', payload: res.data })
                        } else if (gender === 'female' && type === "body") {
                            store.dispatch({ type: 'SET_FEMALE_BODY_AREAS', payload: res.data })
                        } else if (gender === 'male' && type === "face") { 
                            store.dispatch({ type: 'SET_MALE_FACE_AREAS', payload: res.data })
                        } else if (gender === 'male' && type === "body") {
                            store.dispatch({ type: 'SET_MALE_BODY_AREAS', payload: res.data })
                        }

                    }
                    resolve(res);

                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getQuotationPricing(gender, pack, client, light, client_id, quotation_reference, voucher) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/api/quotations/pricing?gender=${gender}&pack=${pack}${light ? "&light=true": ''}${client_id ? "&client_id=" + client_id: ''}${quotation_reference ? "&quotation_reference=" + quotation_reference: ''}${voucher ? "&voucher=" + voucher: ''}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: (new URLSearchParams(client).toString())
            }).then((response) => {

                response.json().then((res) => {

                    resolve(res);

                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getQuotationInformations(quotation_reference) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/api/quotations/${quotation_reference}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }

            }).then((response) => {

                response.json().then((res) => {

                    if (res.status === 200) {
                        store.dispatch({
                            type: 'SET_CLIENT',
                            payload: res.data
                        })
                    }
                    resolve(res);

                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static upgradeQuotation(quotation_reference) {

        return new Promise((resolve, reject) => {

            fetch(`${window.baseUrl}/api/quotations/${quotation_reference}/upgrade`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }

            }).then((response) => {

                response.json().then((res) => {
                    resolve(res);
                });
            })
                .catch((error) => {
                    reject(error);
                });
        });
    }

}
