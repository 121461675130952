
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import { Container, Row, Col } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from "react-activity";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//cmponents
import AreaButton from '../components/AreaButton'
import Circle from '../components/Circle'
import { Title, SubTitle, Label } from '../components/Text'
import StepsHeader from '../components/StepsHeader'
import Input from '../components/Input'


//api
import MyLaserApi from '../api/MyLaserApi'



function Cities() {

    const dispatch = useDispatch()

    const city = useSelector(state => state.city)
    
    return (
        <Col className="mt-4" xs={12} md={4}>
            <Col>
                <Title>Dans quel centre souhaiteriez-vous suivre votre traitement ?</Title>
            </Col>
            <Col className="mt-2">
                <SubTitle>Vous pourrez changer de centre à tout moment.</SubTitle>
            </Col>

            { window.country == "fr" && <Col className="mt-5">

                <AreaButton
                    className={`${city === 1 ? "selected" : ""} `}
                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 1
                    })}

                >
                    Saint-Denis
                </AreaButton>
                <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 2
                    })}
                    className={`${city === 2 ? "selected" : ""} `}

                >
                    Lyon 2
                </AreaButton>
                <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 3
                    })}
                    className={`${city === 3 ? "selected" : ""} `}

                >
                    Marseille
                </AreaButton>
                <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 4
                    })}
                    className={`${city === 4 ? "selected" : ""} `}

                >
                    Toulouse
                </AreaButton>
                <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 5
                    })}
                    className={`${city === 5 ? "selected" : ""} `}

                >
                    Nantes
                </AreaButton>

                <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 6
                    })}
                    className={`${city === 6 ? "selected" : ""} `}

                >
                    Bois Colombes
                </AreaButton>

                <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 7
                    })}
                    className={`${city === 7 ? "selected" : ""} `}

                    >
                    Paris Rivoli
                    </AreaButton>

                    <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 8
                    })}
                    className={`${city === 8 ? "selected" : ""} `}

                    >
                    Nice
                    </AreaButton>

                    <AreaButton

                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 9
                    })}
                    className={`${city === 9 ? "selected" : ""} `}

                    >
                    Bordeaux
                    </AreaButton>

            </Col>}

            { window.country == "ma" && <Col className="mt-5">

                <AreaButton
                    className={`${city === 1 ? "selected" : ""} `}
                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 1
                    })}

                >
                    Casablanca
                </AreaButton>

                <AreaButton
                    className={`${city === 2 ? "selected" : ""} `}
                    onClick={() => dispatch({
                        type: 'SET_CITY',
                        payload: 2
                    })}

                >
                    Tanger
                </AreaButton>

            </Col>}

        </Col>
    )
}

function KnownFromButtons() {

    const dispatch = useDispatch()
    const know_from = useSelector(state => state.know_from)

    return (
        <Col sm={12} md={8} className="mt-3">

            <Label className="px-3">Comment connaissez-vous nos centres ?*</Label>

            <Row className="px-3 mt-2" style={{ width: "100%" }} >

                <Col xs={12} sm={6} md={3} >

                    <AreaButton
                        className={`${know_from === 1 ? "selected" : ""} `}
                        onClick={() => dispatch({
                            type: 'SET_KOWN_FROM',
                            payload: 1
                        })}
                        type="button"
                    >
                        Bouche<br />à oreille
                   </AreaButton>

                </Col>
                <Col xs={12} sm={6} md={3} >
                    <AreaButton

                        onClick={() => dispatch({
                            type: 'SET_KOWN_FROM',
                            payload: 2
                        })}
                        className={`${know_from === 2 ? "selected" : ""}  `}
                        type="button"
                    >
                        Réseaux<br />sociaux
               </AreaButton>

                </Col>
                <Col xs={12} sm={6} md={3} >
                    <AreaButton
                        onClick={() => dispatch({
                            type: 'SET_KOWN_FROM',
                            payload: 3
                        })}
                        className={`${know_from === 3 ? "selected" : ""} `}
                        type="button"
                    >
                        Internet
               </AreaButton>

                </Col>

                <Col sm={6} md={3} >
                    <AreaButton
                        onClick={() => dispatch({
                            type: 'SET_KOWN_FROM',
                            payload: 4
                        })}
                        className={`${know_from === 4 ? "selected" : ""} `}
                        type="button"
                    >
                        Doctolib
               </AreaButton>

                </Col>

            </Row>
        </Col>

    )
}


function QuotationPersonalInformations() {

    const light = useSelector(state => state.light)

    let history = useHistory();

    const selectedAras = useSelector(state => state.selectedAras)
    const gender = useSelector(state => state.gender)
    const packageType = useSelector(state => state.packageType)
    const voucher = useSelector(state => state.voucher)
    const city = useSelector(state => state.city)
    const know_from = useSelector(state => state.know_from)
    const client = useSelector(state => state.client)
    const quotationReference = useSelector(state => state.quotationReference)

    let { register, handleSubmit, formState, setValue } = useForm({
        mode: 'all'
    });

    const dispatch = useDispatch()

    const { isDirty, isValid } = formState;

    let [submitted, setSubmitted] = useState(false)
    let [loading, setLoading] = useState(false)

    useEffect(() => {

        if (client) {
            setValue("lastname", client.lastname, { shouldValidate: true })
            setValue("firstname", client.firstname, { shouldValidate: true })
            setValue("email", client.email, { shouldValidate: true })
            setValue("phone", client.phone_number, { shouldValidate: true })
            setValue("address", client.address, { shouldValidate: true })
            setValue("zip", client.zip_code, { shouldValidate: true })
            setValue("city", client.city, { shouldValidate: true })
        }

        if (light) {

            let price = 0

            let ids = selectedAras.map((el) => {
                price = price + el.price
                return el.id
            })

            MyLaserApi.getQuotationPricing(gender, packageType, {ids: JSON.stringify(ids)}, true).then((res) => {
                
                setLoading(false)

                if (res.status === 200) {

                    dispatch({
                        type: 'SET_PRICING',
                        payload: {
                            old_amount: price,
                            treatement_areas: res.data.items,
                            amount: res.data.price,
                            quotation_id: res.data.quotation_id,
                            quotation_reference: res.data.quotation_reference
                        }
                    })

                    setSubmitted(true)

                } else {
                    toast.error("Une erreur est survenu, merci de réessayer")

                }

            })

        }


        if (client && client.id) {

            let price = 0

            let ids = selectedAras.map((el) => {
                price = price + el.price
                return el.id
            })

            MyLaserApi.getQuotationPricing(gender, packageType, {
                agency_id: client.agency_id,
                ids: JSON.stringify(ids)
            }, false, client.id, quotationReference, voucher).then((res) => {
                
                setLoading(false)

                if (res.status === 200) {

                    dispatch({
                        type: 'SET_PRICING',
                        payload: {
                            old_amount: price,
                            treatement_areas: res.data.items,
                            amount: res.data.price,
                            quotation_id: res.data.quotation_id,
                            quotation_reference: res.data.quotation_reference
                        }
                    })

                    setSubmitted(true)

                } else {
                    toast.error("Une erreur est survenu, merci de réessayer")

                }

            })

        }

    }, [])


    if (selectedAras.length === 0) {

        history.goBack()

    } else if (submitted) {

        return <Redirect to="/ready"></Redirect>

    }


    return (
        <Container fluid style={{ padding: 0 }} className="col-card" >
            {loading && <div className="overlay">
                <Spinner color="#fff" size="28px" />
            </div>
            }
            <StepsHeader
                selected={[1, 2, 3]}
                firstButtonClick={() => history.push('/gender')}
                secondButtonClick={() => history.goBack()}
            />

            <Container style={{ maxWidth: "1400px" }}>


                <Row className={"margin-top"}>
                    <Circle
                        text="3"
                    />

                    <Col  >
                        <Col>
                            <Title>Plus que quelques informations pour finaliser votre devis ! </Title>
                        </Col>
                        <Col >
                            <SubTitle>Remplissez les champs ci-dessous.</SubTitle>
                        </Col>
                    </Col>
                </Row>

                <Row >

                    <Cities />

                    <Col className="mt-4" xs={12} md={8}>

                        <Col>
                            <Title>Veuillez remplir le formulaire</Title>
                        </Col>
                        <Col className="mt-2">
                            <SubTitle>Nous avons besoin de certaines informations, celles-ci resteront <br />confidentielles.</SubTitle>
                        </Col>

                        <form
                            onSubmit={handleSubmit((data) => {

                                setLoading(true)

                                let agency_id = null

                                if (window.country === "fr") {

                                    agency_id = ({ 
                                        1: 2, 
                                        2: 3, 
                                        3: 4,
                                        4: 5,
                                        5: 6,
                                        6: 7,
                                        7: 8,
                                        8: 9,
                                        9: 10,
                                    })[city]
                                    
                                }

                                if (window.country === "ma") {
                                    agency_id = city
                                }

                                let client = {
                                    lastname: data.lastname,
                                    firstname: data.firstname,
                                    email: data.email,
                                    phone_number: data.phone,
                                    address: data.address,
                                    zip_code: data.zip,
                                    city: data.city,
                                    sexe: gender === "female" ? "femme" : "homme",
                                    agency_id: agency_id,
                                    know_from: ({ 1: "Bouche à oreille", 2: "Réseaux sociaux", 3: "Internet", 4: "Doctolib" })[know_from]
                                }

                                dispatch({
                                    type: 'SET_CLIENT',
                                    payload: client
                                })

                                let price = 0

                                let ids = selectedAras.map((el) => {
                                    price = price + el.price
                                    return el.id
                                })

                                client.ids = JSON.stringify(ids)

                                MyLaserApi.getQuotationPricing(gender, packageType, client).then((res) => {

                                    setLoading(false)

                                    if (res.status === 200) {

                                        dispatch({
                                            type: 'SET_PRICING',
                                            payload: {
                                                old_amount: price,
                                                treatement_areas: res.data.items,
                                                amount: res.data.price,
                                                quotation_id: res.data.quotation_id,
                                                quotation_reference: res.data.quotation_reference
                                            }
                                        })

                                        setSubmitted(true)

                                    } else {
                                        toast.error("Une erreur est survenu, merci de réessayer")
                                    }

                                })

                            })}>

                            <Row className="mt-3" >

                                <Col sm={6} md={4}>

                                    <Label>Nom*</Label>
                                    <Input
                                        className={"th-textbox mt-2"}

                                        type="text"
                                        placeholder="Nom"

                                        {...register("lastname", { required: true, minLength: 2 })}

                                    />

                                </Col>

                                <Col sm={6} md={4}>

                                    <Label>Prénom*</Label>
                                    <Input
                                        className={"th-textbox mt-2"}
                                        type="text"
                                        placeholder="Prénom"
                                        {...register("firstname", { required: true, minLength: 2 })}
                                    />

                                </Col>

                                <Col sm={6} md={4}>

                                    <Label>Email*</Label>
                                    <Input
                                        type="email"
                                        className={"th-textbox mt-2"}
                                        placeholder="Email"
                                        {...register("email", {
                                            required: true, minLength: 2, pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            }
                                        })}
                                    />

                                </Col>

                                <Col sm={6} md={4} className="mt-3">

                                    <Label>N° de téléphone*</Label>
                                    <Input
                                        type="tel"
                                        className="th-textbox mt-2"
                                        placeholder="N° de téléphone"
                                        {...register("phone", {
                                            required: true, pattern: {
                                                value: /^(\+33|0)[1-9](\d\d){4}$/,
                                                message: "Le champs n° de téléphone n'est pas valide"
                                            }
                                        })}

                                    />

                                </Col>

                                <Col sm={6} md={4} className="mt-3">

                                    <Label>Adresse*</Label>
                                    <Input
                                        type="text"
                                        className="th-textbox mt-2"
                                        placeholder="Adresse"
                                        {...register("address", { required: true, minLength: 2 })}
                                    />

                                </Col>

                                <Col sm={6} md={4} className="mt-3">

                                    <Label>Code Postal*</Label>
                                    <Input
                                        type="text"
                                        className="th-textbox mt-2"
                                        placeholder="Code Postal"
                                        {...register("zip", { required: true, minLength: 2 })}
                                    />

                                </Col>

                                <Col sm={6} md={4} className="mt-3">

                                    <Label>Ville*</Label>
                                    <Input
                                        type="text"
                                        className="th-textbox mt-2"
                                        placeholder="Ville"
                                        {...register("city", { required: true, minLength: 2 })}
                                    />

                                </Col>

                                <KnownFromButtons />

                            </Row>
                            
                            <Input
                                type="submit"
                                value="Valider"
                                disabled={!isDirty || !isValid || city === null}
                                className={`form-button ${!isDirty || !isValid || city === null ? 'disabled' : ''}`}
                            />

                        </form>
                    </Col>
                </Row>



            </Container>

        </Container >
    );
}

export default QuotationPersonalInformations;
