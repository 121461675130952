
import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import styled from 'styled-components'
import { Container, Row, Col, Image } from 'react-bootstrap'
import {
    Link,
    useLocation
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

//components
import { OptionButton } from '../components/OptionButton'
import Button from '../components/Button'
import Circle from '../components/Circle'
import { Title, SubTitle } from '../components/Text'
import StepsHeader from '../components/StepsHeader'


import Swal from 'sweetalert2';

//api
import MyLaserApi from '../api/MyLaserApi'


const queryString = require('query-string');


const Text = styled.text`
    font-family: Quicksand_Book ;
    font-size: 65px ;
`

function QuotationGender(props) {

    const dispatch = useDispatch()
    let location = useLocation();

    const packageType = useSelector(state => state.packageType)
    const gender = useSelector(state => state.gender) 
    const client = useSelector(state => state.client) || {}
    const voucher = useSelector(state => state.voucher)

    useEffect(() => {

        if (location.search === "?light=true") {

            dispatch({ type: 'SET_LIGHT', payload: true })

            dispatch({ type: "SET_CLIENT", payload: {
                lastname: "Simulation",
                firstname: "Simulation",
                email: "simulation@my-laser.fr",
                phone_number: "0600000000",
                address: "Simulation",
                zip_code: "00000",
                city: "Simulation",
                sexe: "simulation",
                agency_id: 1,
                know_from: 1
            }})

        }

        let arrayQueries = []

        if (location.search.length > 0) {

            let parsedQueries = queryString.parse(location.search)

            if (parsedQueries.gender) {

                if (parsedQueries.gender === "femme" || parsedQueries.gender === "Femme") {
                    dispatch({ type: 'SET_GENDER', payload: "female" })
                } else if (parsedQueries.gender === "homme" || parsedQueries.gender === "Homme") {
                    dispatch({ type: 'SET_GENDER', payload: "male" })
                } else {
                    dispatch({ type: 'SET_GENDER', payload: parsedQueries.gender })
                }

                
            }

            if (parsedQueries.voucher) {
                dispatch({ type: 'SET_VOUCHER', payload: parsedQueries.voucher })
            }

            if (parsedQueries.pack) {
                dispatch({ type: 'SET_PACKAGE', payload: parsedQueries.pack })
            }

            if (parsedQueries.items) {
                arrayQueries = JSON.parse("[" + parsedQueries.items + "]")
            }

            if (parsedQueries.reference) {
                //4P0329ICB
                // MyLaserApi.getQuotationInformations(parsedQueries.reference)
                console.log(parsedQueries.reference)
                dispatch({ type: 'SET_QUOTATION_REFERENCE', payload: parsedQueries.reference })
            }

            if (parsedQueries.client) {
                //4P0329ICB
                // MyLaserApi.getQuotationInformations(parsedQueries.client)

                let client = JSON.parse(atob(decodeURIComponent(parsedQueries.client)))

                dispatch({ type: "SET_CLIENT", payload: {
                    id: client.id,
                    lastname: client.lastname,
                    firstname: client.firstname,
                    email: "",
                    phone_number: "",
                    address: "",
                    zip_code: "",
                    city: "",
                    sexe: "",
                    agency_id: client.agency_id,
                    know_from: 1
                }})

            }
        }

        let promises = [];
        let ids = []

        promises.push(
            MyLaserApi.getQuotationItems('female', 'face'),
            MyLaserApi.getQuotationItems('female', 'body'),
            MyLaserApi.getQuotationItems('male', 'face'),
            MyLaserApi.getQuotationItems('male', 'body'),
        )

        Promise.all(promises).then(([femaleface, femalebody, maleface, malebody]) => {

            if (arrayQueries.length > 0) {


                if (gender === "female") {
                    femaleface.data.map((el) => {
                        ids.push(el)
                    })
                    femalebody.data.map((el) => {
                        ids.push(el)
                    })
                } else {
                    maleface.data.map((el) => {
                        ids.push(el)
                    })
                    malebody.data.map((el) => {
                        ids.push(el)
                    })
                }


                let selected = []

                for (let index2 = 0; index2 < ids.length; index2++) {
                    

                    const el = ids[index2];


                    for (let index = 0; index < arrayQueries.length; index++) {
                        const id = arrayQueries[index];
                        
                        if (el.id === id) {
                            selected.push({...el, forced: true})
                        }
                    }

                }

                dispatch({ type: 'SET_SELECTED_AREAS', payload: selected })
            }
        });

    }, [])

    return (
        <Container fluid style={{ padding: 0 }} className="col-card" >
            <StepsHeader
                selected={[1]}
            />
            <Container style={{ maxWidth: "1400px" }}>
                <Col md={12}>
                    <Row className={"mt-4"} >
                        <Circle
                            text="1"
                        />

                        <Col  >
                            <Title>Veuillez répondre aux questions</Title>
                            <SubTitle>Cliquez sur la réponse souhaitée.</SubTitle>

                        </Col>
                    </Row>
                    <Row >
                        {!client.id && <Col md={6} className="mt-4"  >

                            <Title className={`d-none d-lg-none d-xl-block`}>Êtes-vous ?</Title>
                            <Title className={`d-xl-none`} style={{ fontSize: 14 }}>Êtes-vous ?</Title>
                            <Row  >
                                <Col md={6} sm={6} >
                                    <OptionButton

                                        className={`${gender === "female" ? "selected" : ""} mt-4 `}
                                        onClick={() => {

                                            dispatch({ type: 'SET_GENDER', payload: 'female' })
                                            dispatch({ type: 'SET_SELECTED_AREAS', payload: [] })
                                        }}
                                    >
                                        <Image src={gender === 'female' ? "/woman-pink.svg" : "/woman.svg"} />
                                        Une femme
                                    </OptionButton>
                                </Col>
                                <Col md={6} sm={6}>
                                    <OptionButton

                                        className={`${gender === "male" ? "selected" : ""} mt-4`}
                                        onClick={() => {

                                            dispatch({ type: 'SET_GENDER', payload: 'male' })
                                            dispatch({ type: 'SET_SELECTED_AREAS', payload: [] })
                                        }}

                                    >
                                        <Image src={gender === 'male' ? "/man-pink.svg" : "/man.svg"} />
                                        Un homme
                                    </OptionButton>
                                </Col>

                            </Row>

                        </Col> }
                        
                        <Col md={client.id ? 12 : 6} className="mt-4" >

                            <Title className={`d-none d-lg-none d-xl-block`}>Quel type de traitement souhaitez-vous suivre ? </Title>
                            <Title className={`d-xl-none`} style={{ fontSize: 14 }}>Quel type de traitement souhaitez-vous suivre ? </Title>
                            <Row  >
                                <Col>
                                    <OptionButton
                                        className={`${packageType === 'x1' ? "selected" : ""} mt-4`}
                                        onClick={() => {

                                            dispatch({ type: 'SET_PACKAGE', payload: 'x1' })
                                        }}
                                    >
                                        <Text style={{fontSize: "30px"}}>À la séance</Text>
                                        {/* 1 Zone */}
                                    </OptionButton>
                                </Col>
                                <Col>
                                    <OptionButton
                                        className={`${packageType === 'x5' ? "selected" : ""} mt-4`}
                                        onClick={() => {

                                            dispatch({ type: 'SET_PACKAGE', payload: 'x5' })
                                        }}
                                    >
                                        <Text style={{fontSize: "30px"}}>Forfait 5 séances</Text>
                                        Multi-Zones
                                    </OptionButton>
                                </Col>

                            </Row>

                        </Col>

                        

                        {client.id && <Col md={12} className="mt-4" >

                            <Title className={`d-none d-lg-none d-xl-block`}>Le patient a le droit à une réduction ?</Title>
                            <Title className={`d-xl-none`} style={{ fontSize: 14 }}>Le patient a le droit à une réduction ?</Title>
                            <Row>
                                <Col>
                                    <OptionButton
                                        className={`${voucher === 'Aucune' ? "selected" : ""} mt-4`}
                                        onClick={() => {
                                            dispatch({ type: 'SET_VOUCHER', payload: 'Aucune' })
                                        }}
                                    >
                                        <Text style={{fontSize: "30px"}}>Aucune</Text>
                                    </OptionButton>
                                </Col>
                                <Col>
                                    <OptionButton
                                        className={`${voucher === 'Etudiant' ? "selected" : ""} mt-4`}
                                        onClick={() => {
                                            dispatch({ type: 'SET_VOUCHER', payload: 'Etudiant' })
                                        }}
                                    >
                                        <Text style={{fontSize: "30px"}}>Etudiant</Text>
                                    </OptionButton>
                                </Col>

                                <Col>
                                    <OptionButton
                                        className={`${voucher !== 'Etudiant' && voucher !== "Aucune" ? "selected" : ""} mt-4`}
                                        onClick={() => {

                                            Swal.fire({
                                                title: 'Merci de spécifier le nom de l\'influenceur',
                                                input: 'text',
                                                inputAttributes: {
                                                    autocapitalize: 'off'
                                                },
                                                showCancelButton: true,
                                                confirmButtonText: 'Confirmer',
                                                cancelButtonText: "Annuler"
                                            }).then((result) => {

                                                if (result.isConfirmed) {

                                                    if (result.value) {
                                                        dispatch({ type: 'SET_VOUCHER', payload: result.value })
                                                    }

                                                }

                                            })

                                        }}
                                    >
                                        <Text style={{fontSize: "30px"}}>Influenceur</Text>
                                        {voucher !== 'Etudiant' && voucher !== "Aucune" && voucher}
                                    </OptionButton>
                                </Col>

                            </Row>

                            </Col>}
                        

                    </Row>
                </Col>
                <Link
                    // className="bottom-div"
                    style={{
                        "marginTop": "35px", 
                        "width": "98%",
                        "marginLeft": "auto",
                        "marginRight": "auto"


                    }}
                    to={{
                        pathname: "/areas",
                        gender: gender
                    }}

                >
                    <Button

                    >
                        Étape suivante
                    </Button>
                </Link>
            </Container>


        </Container>
    );
}

export default QuotationGender;
