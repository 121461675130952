import { createStore, combineReducers } from 'redux'

const initialGlobalState = {
    areas: [],
    femaleFaceAreas: [],
    femaleBodyAreas: [],
    maleFaceAreas: [],
    maleBodyAreas: [],
    gender: "female",
    type: "body",
    selectedAras: [],
    packageType: 'x1',
    client: null,
    pricing: null,
    city: null,
    know_from: 1,
    light: false,
    voucher: "Aucune"
}

function globalReducer(state = initialGlobalState, action) {

    switch (action.type) {

        case 'SET_AREAS':
            return { ...state, ...{ areas: action.payload } }
        case 'SET_GENDER':
            return { ...state, ...{ gender: action.payload } }
        case 'SET_TYPE':
            return { ...state, ...{ type: action.payload } }
        case 'SET_SELECTED_AREAS':
            return { ...state, ...{ selectedAras: action.payload } }
        case 'SET_PACKAGE':
            return { ...state, ...{ packageType: action.payload } }
        case 'SET_VOUCHER':
            return { ...state, ...{ voucher: action.payload } }
        case 'SET_QUOTATION_REFERENCE':
            return { ...state, ...{ quotationReference: action.payload } }
        case 'SET_FEMALE_FACE_AREAS':
            return { ...state, ...{ femaleFaceAreas: action.payload } }
        case 'SET_FEMALE_BODY_AREAS':
            return { ...state, ...{ femaleBodyAreas: action.payload } }
        case 'SET_MALE_FACE_AREAS':
            return { ...state, ...{ maleFaceAreas: action.payload } }
        case 'SET_MALE_BODY_AREAS':
            return { ...state, ...{ maleBodyAreas: action.payload } }
        case 'SET_CLIENT':
            return { ...state, ...{ client: action.payload } }
        case 'SET_PRICING':
            return { ...state, ...{ pricing: action.payload } }
        case 'SET_CITY':
            return { ...state, ...{ city: action.payload } }
        case 'SET_KOWN_FROM':
            return { ...state, ...{ know_from: action.payload } }
        case 'SET_LIGHT':
            return { ...state, ...{ light: action.payload } }

        default:
            return state

    }

}

const store = createStore(globalReducer)

export default store