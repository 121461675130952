import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css';
import { Link, useHistory } from 'react-router-dom';

import { Container, Row, Col, Image, Card, ListGroup, Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'


import AreaButton from '../components/AreaButton'
import Button from '../components/Button'
import Circle from '../components/Circle'
import { Title, SubTitle, CardTitle, CardDeleteText } from '../components/Text'

import StepsHeader from '../components/StepsHeader'



function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg" 
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header className="p-5" closeButton style={{ border: 0 }}>
                <Col>
                    <Col>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Des questions sur les différents types de maillots ?
                    </Modal.Title>
                    </Col>
                    <Col>
                        Voici les schémas de nos différentes épilations.
                </Col>
                </Col>

            </Modal.Header>
            <Modal.Body class="p-5 d-flex justify-content-center" >
                <Image src={"/shaving-types.svg"}
                    style={{ maxWidth: '80%' }}
                />

            </Modal.Body>

        </Modal>
    );
}


function QuotationAreas() {

    let history = useHistory();

    const gender = useSelector(state => state.gender)
    const type = useSelector(state => state.type)

    const femaleFaceAreas = useSelector(state => state.femaleFaceAreas)
    const femaleBodyAreas = useSelector(state => state.femaleBodyAreas)
    const maleFaceAreas = useSelector(state => state.maleFaceAreas)
    const maleBodyAreas = useSelector(state => state.maleBodyAreas)
    const selectedAras = useSelector(state => state.selectedAras)
    const packageType = useSelector(state => state.packageType)
    const quotationReference = useSelector(state => state.quotationReference)
    const dispatch = useDispatch()

    const [modalShow, setModalShow] = React.useState(false);
    const [areas, setAreas] = useState([]);


    useEffect(() => {
        if (gender === 'female' && type === "face") {
            setAreas(femaleFaceAreas)

        } else if (gender === 'female' && type === "body") {
            setAreas(femaleBodyAreas)

        } else if (gender === 'male' && type === "face") {
            setAreas(maleFaceAreas)
        } else if (gender === 'male' && type === "body") {
            setAreas(maleBodyAreas)
        }

    }, [type])




    let checkItem = (item) => {

        let checkedItems = selectedAras

        console.log(selectedAras)

        if (!checkedItems.find(i => i.id === item.id)) {

            let npayload = [...checkedItems, item]

            if (!quotationReference) {

                // DOS

                if (item.name == "Dos Entier") {

                    if (npayload.find(x => x.name == "Haut du dos") )
                        npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Haut du dos")[0].id )

                    if (npayload.find(x => x.name == "Bas du dos") )
                        npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Bas du dos")[0].id )

                } else if ( (item.name == "Haut du dos" || item.name == "Bas du dos") && npayload.find(x => x.name == "Dos Entier" )) {
                    return;
                } else if ( (item.name == "Haut du dos" || item.name == "Bas du dos") && npayload.find(x => x.name == "Haut du dos") && npayload.find(x => x.name == "Bas du dos")) {

                    let ix = areas.filter(x => x.name == "Dos Entier")[0]

                    npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Haut du dos")[0].id )
                    npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Bas du dos")[0].id )
                    npayload = npayload.filter((a) => a.id !== ix.id )

                    npayload = [...npayload, ix]
                } 

                // BRAS

                if (item.name == "Bras Entiers") {

                    if (npayload.find(x => x.name == "Avant-bras et mains") )
                        npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Avant-bras et mains")[0].id )

                    if (npayload.find(x => x.name == "Bras Supérieurs") )
                        npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Bras Supérieurs")[0].id )

                } else if ( (item.name == "Avant-bras et mains" || item.name == "Bras Supérieurs") && npayload.find(x => x.name == "Bras Entiers" )) {
                    return;
                } else if ( (item.name == "Avant-bras et mains" || item.name == "Bras Supérieurs") && npayload.find(x => x.name == "Avant-bras et mains") && npayload.find(x => x.name == "Bras Supérieurs")) {

                    let ix = areas.filter(x => x.name == "Bras Entiers")[0]

                    npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Avant-bras et mains")[0].id )
                    npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Bras Supérieurs")[0].id )
                    npayload = npayload.filter((a) => a.id !== ix.id )

                    npayload = [...npayload, ix]
                } 

                // JAMBES

                if (item.name == "Jambes Entières") {

                    if (npayload.find(x => x.name == "Cuisses") )
                        npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Cuisses")[0].id )

                    if (npayload.find(x => x.name == "Demi-jambes") )
                        npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Demi-jambes")[0].id )

                } else if ( (item.name == "Cuisses" || item.name == "Demi-jambes") && npayload.find(x => x.name == "Jambes Entières" )) {
                    return;
                } else if ((item.name == "Cuisses" || item.name == "Demi-jambes") && npayload.find(x => x.name == "Cuisses") && npayload.find(x => x.name == "Demi-jambes")) {

                    let ix = areas.filter(x => x.name == "Jambes Entières")[0]

                    npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Cuisses")[0].id )
                    npayload = npayload.filter((a) => a.id !== npayload.filter(x => x.name == "Demi-jambes")[0].id )
                    npayload = npayload.filter((a) => a.id !== ix.id )

                    npayload = [...npayload, ix]
                } 

            }

            dispatch({ type: 'SET_SELECTED_AREAS', payload: npayload })

        } else {

            let itt = checkedItems.find((a) => a.id === item.id)

            console.log(itt)

            if (!itt.forced) {
                dispatch({ type: 'SET_SELECTED_AREAS', payload: checkedItems.filter((a) => a.id !== item.id) })
            }

        }

    };


    return (
        <Container fluid style={{ padding: 0 }} className="col-card" >
            <StepsHeader
                selected={[1, 2]}
                firstButtonClick={() => history.goBack()}
            />

            <Container style={{ maxWidth: "1400px"}}>

                <Row>

                    <div className={`col-md-6 col-lg-7 col-xl-${type === "body" ? 5 : 4}`} >
                        <Row className="margin-top">
                            <Circle
                                text="2"
                            />
                            <Col  >
                                <Title>Quelles zones souhaitez-vous traiter ?</Title>
                                <SubTitle>Cliquez sur la ou les zones souhaitées</SubTitle>

                            </Col>

                        </Row>

                        <Row className="margin-top"  >

                            <Col >
                                <Row className="margin-top" >
                                    <Col className="col-card" >
                                        <Title className={type === "body" ? "" : "grayText"} style={{ cursor: 'pointer' }}
                                            onClick={() => {

                                                dispatch({ type: 'SET_TYPE', payload: 'body' })
                                            }}
                                            style={{"marginLeft": "35px"}}
                                        >CORPS</Title>
                                        {type === "body" && <div className="line" />}
                                    </Col>
                                    <Col className="col-card" >
                                        <Title className={type === "face" ? "" : "grayText"} style={{ cursor: 'pointer' }}
                                            onClick={() => {

                                                dispatch({ type: 'SET_TYPE', payload: 'face' })
                                            }}
                                            style={{"marginLeft": "35px"}}
                                        >VISAGE</Title>
                                        {type === "face" && <div className="line" />}

                                    </Col>
                                </Row>


                                <Col className="mt-4" style={{ marginRight: 5, marginLeft: 5,paddingLeft: 1}}>
                                    <ul className={type === "body" ? "list-col3" : "list-col2"}>
                                        {(() => {
                                            return areas.map((el, index) => {

                                                return <AreaButton
                                                    className={`
                                                        ${selectedAras.some(selectedEl => el.id === selectedEl.id) ? "selected" : ""}
                                                        ${(el.name == "Bas du dos" || el.name == "Haut du dos") && selectedAras.find(x => x.name == "Dos Entier") ? "disabled" : ""}
                                                        ${(el.name == "Avant-bras et mains" || el.name == "Bras Supérieurs") && selectedAras.find(x => x.name == "Bras Entiers") ? "disabled" : ""}
                                                        ${(el.name == "Cuisses" || el.name == "Demi-jambes") && selectedAras.find(x => x.name == "Jambes Entières") ? "disabled" : ""}
                                                    `}
                                                    onClick={() => checkItem(el, index)}
                                                >

                                                    <Row className='row' style={{ justifyContent: ["Maillot Classique", "Maillot Échancré", "Maillot Brésilien", "Maillot Intégral"].includes(el.name) ? 'center' : 'center', marginRight: 0, marginLeft: 0 }}>

                                                        {el.name}

                                                        {["Maillot Classique", "Maillot Échancré", "Maillot Brésilien", "Maillot Intégral"].includes(el.name) &&

                                                            <div style={{"marginLeft": "5px"}}>
                                                                <Image src={selectedAras.includes(el) ? "/info-pink.svg" : "/info.svg"}
                                                                    //  onClick={() => setModalShow(true)}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setModalShow(true)
                                                                    }}
                                                                />
                                                            </div>

                                                        }

                                                    </Row>


                                                </AreaButton>
                                            })
                                        })()

                                        }


                                    </ul>

                                </Col>
                            </Col>


                        </Row>
                    </div>

                    <div className={`d-none d-lg-none d-xl-block col-xl-${type === "body" ? 4 : 5}`} style={{ textAlign: 'center', backgroundColor: 'transparent', overflow: "hidden" }}>

                        {/* <div className={`${type === "body" ? 'col-xl-4' : 'col-xl-5'} d-lg-none`} style={{ textAlign: 'center', backgroundColor: 'transparent' }}> */}

                        <div className="image">
                            <Image src={(() => {
                                if (gender === "female") {
                                    if (type === "body") {
                                        return "/fullwoman.svg"
                                    } else {
                                        return "/woman-face.svg"
                                    }
                                } else {
                                    if (type === "body") {
                                        return "/fullman.svg"
                                    } else {
                                        return "/man-face.svg"
                                    }
                                }

                            })()
                            }
                            />

                            {selectedAras.map((el, index) => {

                                if (areas.some(selectedEl => el.id === selectedEl.id))
                                    return <div className="area-style" style={el.style} />
                            })
                            }
                        </div>


                    </div>

                    <div className='col-md-6 col-lg-5 col-xl-3' style={{paddingLeft: 0}}>
                        <Card className="card">
                            <Card.Header className="card-header" style={{ width: '100%', textAlign: 'center' }}>ZONES SÉLECTIONNÉES</Card.Header>
                            <ListGroup className="list-group">
                                {selectedAras.map((el, index) => {
                                    return <ListGroup.Item bsPrefix="list-style">
                                        <div class="d-flex row space-between" >
                                            <CardTitle >{el.name}</CardTitle>
                                            <CardDeleteText
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => checkItem(el, index)}
                                            >Supprimer</CardDeleteText>
                                        </div>
                                    </ListGroup.Item>
                                })
                                }


                            </ListGroup>

                            {(() => {

                                let disabled = false
                                
                                if (packageType === "x5") {

                                    if (selectedAras.length == 0 || selectedAras.length == 1) {
                                        disabled = true
                                    }

                                    if (selectedAras.find(x => x.name === "Dos Entier" || x.name === "Bras Entiers" || x.name === "Jambes Entières")) {
                                        disabled = false
                                    }

                                } else {

                                    if (selectedAras.length == 0) {
                                        disabled = true
                                    }

                                }

                                return (

                                    <Link
                                        className={`bottom-div ${!disabled ? '' : 'disabled-link'}`}
                                        style={{ width: '80%', position: "absolute" }}
                                        to={{ pathname: "/informations" }}
                                        >
                                        <Button
                                            className={`${!disabled ? '' : 'disabled'}`}
                                        >
                                            Valider les zones
                                        </Button>
                                    </Link>

                                )

                            })()}

                        </Card>
                        
                    </div>

                </Row>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </Container>



        </Container >
    );
}

export default QuotationAreas;
